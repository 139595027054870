import { type APIError, useApiMutation, useApiQuery } from "@api/fetch"
import { useAuth, useUser } from "@clerk/clerk-react"
import { useCanvasToPng } from "@machinery/useCanvasToPng"
import type {
  CreditsResponse,
  GlobalComponent,
  ImageNewResponse,
  SelectedFile,
  WithCredits
} from "@repo/types"
import { Route } from "@routes/_auth.editor.$id"
import { useCredits } from "@state/useCredits"
import { useSuspenseQuery } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { useEffect, useRef } from "react"

import projectQueries from "@api/queries/projects"
import cx from "classnames"

import { PaintModule2 } from "./PaintModule2"
import { Toolbar } from "./Toolbar"
import { Uploader } from "./Uploader"

import { useDrawingStore } from "@state/useDrawStore"
import { useFile } from "@state/useFile"
import { useSize } from "@state/useSize"

import logo from "@assets/logo-icon.svg"

import styles from "./Workspace.module.css"

export function Workspace() {
  const { getToken } = useAuth()
  const { id } = Route.useParams()
  const { data: project } = useSuspenseQuery(
    projectQueries.detail(id, getToken)
  )

  const { undo, setMode, removeLines } = useDrawingStore()
  const lines = useDrawingStore((x) => x.lines)

  const { set: setFile } = useFile()

  const base64 = useFile((x) => x.base64)
  const name = useFile((x) => x.name)

  const { set: setSize } = useSize()

  const mutation = useApiMutation<ImageNewResponse & WithCredits>("/falai", {
    onSuccess: handleSuccess,
    onError: handleError
  })

  const paintModuleRef = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { pngUrl } = useCanvasToPng(canvasRef.current, paintModuleRef.current)

  const { user } = useUser()
  const { balance: currentBalance, set: setCredits } = useCredits()

  const { data } = useApiQuery<CreditsResponse>(
    ["credits", user?.id],
    "/credits/balance"
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentBalance !== data?.balance && data?.balance)
      setCredits(data.balance)
  }, [currentBalance, data])

  const file: SelectedFile = name
    ? { name, base64 }
    : { name: null, base64: null }

  return (
    <section className={styles.component}>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {/* <img alt='' style={{ display: "none" }} src={pngUrl} /> */}

      <Uploader
        onFile={setFile}
        layoutClassName={styles.uploaderLayout}
        {...{ file, mutation }}
      />

      {mutation.isPending && <p className={styles.loading}>loading...</p>}

      <PaintModule2
        file={base64 || project?.image?.url || undefined}
        onSize={setSize}
        ref={paintModuleRef}
        layoutClassName={styles.paintModuleLayout}
      />

      <Logo layoutClassName={styles.logoLayout} />

      <Toolbar
        busy={mutation.isPending}
        onUndo={undo}
        onMode={setMode}
        onSend={handleSubmit}
        layoutClassName={styles.toolbarLayout}
      />

      <History
        history={project.generations}
        layoutClassName={styles.historyLayout}
      />
    </section>
  )

  function handleSubmit() {
    mutation.mutate({
      id,
      fileName: name || null,
      mask: lines?.length ? pngUrl : null,
      file: base64 || project?.image?.url || null
    })
  }

  async function handleSuccess({
    url,
    balance
  }: ImageNewResponse & WithCredits) {
    // TODO: Also update history / genrations
    setFile({ base64: url })
    setCredits(balance)
    removeLines()
  }

  function handleError(e: APIError) {
    console.log("error", e)
  }
}

function History({ history, layoutClassName }: GlobalComponent) {
  return (
    <div className={cx(styles.componentHistory, layoutClassName)}>
      {history.map((x: string) => (
        <img alt='' key={x} src={x} className={styles.image} />
      ))}
    </div>
  )
}

function Logo({ layoutClassName }: GlobalComponent) {
  return (
    <Link to='/new' className={cx(styles.componentLogo, layoutClassName)}>
      <img className={styles.logo} alt='' src={logo} />
    </Link>
  )
}
