import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  useAuth,
  useUser
} from "@clerk/clerk-react"
import type {
  SliderInputProps,
  ToolbarButtonProps,
  ToolbarProps
} from "@repo/types"
import { useCredits } from "@state/useCredits"
import { useDrawingStore } from "@state/useDrawStore"
import { Link } from "@tanstack/react-router"

import cx from "classnames"

import styles from "./Toolbar.module.css"

import { useApiMutation } from "@api/fetch"
import iconBolt from "@icons/bolt.svg?raw"
import iconBrush from "@icons/brush.svg?raw"
import iconGallery from "@icons/gallery.svg?raw"
import iconSend from "@icons/send.svg?raw"
import iconUndo from "@icons/undo.svg?raw"
import { queryClient } from "@root/main"

export function Toolbar({
  busy,
  onUndo,
  onSend,
  onMode,
  layoutClassName = undefined
}: ToolbarProps) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Actions {...{ busy, onSend, onUndo, onMode }} />
      <Profile />
    </div>
  )
}

function Actions({
  busy,
  onSend,
  onUndo,
  onMode
}: Pick<ToolbarProps, "busy" | "onSend" | "onUndo" | "onMode">) {
  const brushThickness = useDrawingStore((x) => x.brushThickness)
  const { setBrushThickness } = useDrawingStore()

  return (
    <div className={styles.componentActions}>
      <ToolbarButton onClick={onUndo} disabled={busy}>
        <UndoIcon />
      </ToolbarButton>

      <ToolbarButton onClick={() => onMode("feather")} disabled={busy}>
        <BrushIcon />
      </ToolbarButton>

      <ToolbarButton onClick={() => onMode("brush")} disabled={busy}>
        <BrushIcon />
      </ToolbarButton>

      <ToolbarButton onClick={() => onMode("lasso")} disabled={busy}>
        <BoltIcon />
      </ToolbarButton>

      <SliderInput
        disabled={busy}
        value={brushThickness}
        layoutClassName={styles.sliderLayout}
        onSlideChange={setBrushThickness}
      />

      <ToolbarButton disabled={busy} onClick={onSend}>
        {busy ? <Loading /> : <SendButton />}
      </ToolbarButton>
    </div>
  )
}

function Profile() {
  const balance = useCredits((x) => x.balance)
  const { user } = useUser()

  const { mutate: increaseMutation } = useApiMutation("/credits/increase", {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["credits", user?.id] })
  })

  const { mutate: decreaseMutation } = useApiMutation("/credits/decrease", {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["credits", user?.id] })
  })

  return (
    <div className={styles.componentProfile}>
      <code className={styles.balance}>{balance}</code>

      <Link to='/gallery' className={styles.link}>
        <GalleryIcon />
      </Link>

      <div>
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          {/**
            NOTE: Hier kan settings in.
            @see https://clerk.com/docs/customization/user-profile 
          */}
          <UserButton>
            <UserButton.UserProfilePage
              label='Admin Settings'
              url='/admin'
              labelIcon={<div />}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                <button type='button' onClick={() => increaseMutation({})}>
                  increase with 1 credit
                </button>

                <button type='button' onClick={() => decreaseMutation({})}>
                  decrease with 1 credit
                </button>
              </div>
            </UserButton.UserProfilePage>
          </UserButton>
        </SignedIn>
      </div>
    </div>
  )
}

function ToolbarButton({
  children,
  onClick,
  disabled = false,
  layoutClassName = undefined
}: ToolbarButtonProps) {
  return (
    <button
      type='button'
      className={cx(styles.componentButton, layoutClassName)}
      {...{ disabled, onClick }}
    >
      {children}
    </button>
  )
}

function BoltIcon() {
  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
  return <span dangerouslySetInnerHTML={{ __html: iconBolt }} />
}

function UndoIcon() {
  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
  return <span dangerouslySetInnerHTML={{ __html: iconUndo }} />
}

function BrushIcon() {
  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
  return <span dangerouslySetInnerHTML={{ __html: iconBrush }} />
}

function SendButton() {
  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
  return <span dangerouslySetInnerHTML={{ __html: iconSend }} />
}

function GalleryIcon() {
  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
  return <span dangerouslySetInnerHTML={{ __html: iconGallery }} />
}

function Loading() {
  return <span className={styles.componentLoader} />
}

function SliderInput({
  value,
  disabled,
  onSlideChange,
  layoutClassName
}: SliderInputProps) {
  return (
    <div className={cx(styles.componentSliderInput, layoutClassName)}>
      <input
        type='range'
        min={1}
        max={30}
        className={cx(styles.slider, layoutClassName)}
        onChange={(e) => onSlideChange(Number(e.currentTarget.value))}
        style={{ "--value": (value - 1) / (30 - 1) } as React.CSSProperties}
        {...{ disabled, value }}
      />
    </div>
  )
}
